import { combineReducers } from 'redux'
import { SpinnerState, spinnerReducer } from './spinner.reducer'

/** Maintain alphabetical order. If in order will help while debugging using redux tools */
export type RootState = {
    spinner: SpinnerState
}

/** Maintain alphabetical order. If in order will help while debugging using redux tools */
const reducers = combineReducers({
    spinner: spinnerReducer,
})

export default reducers
