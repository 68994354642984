// External Integrations
import ReactCMSComp from 'react-cms-components/index'

// eslint-disable-next-line sonar/no-wildcard-import
import * as serviceWorker from './serviceWorker'
import handleAppInit from './appInit/index'
import { renderAEMComponents, renderDynamicReactComponent } from './helpers/cms.helper'
import { ComponentType } from './helpers/cms.helper.type'

const componentArray = ReactCMSComp.return({
    componentList: document.querySelectorAll('[data-component]'),
})

// For rendering AEM components
renderAEMComponents(document)

if (componentArray.length > 0) {
    document.addEventListener('DOMContentLoaded', handleAppInit)
    componentArray.forEach((component: ComponentType) => {
        renderDynamicReactComponent(component)
    })
}

serviceWorker.unregister()
