import { GlobalHelperConfig, ISEOmetadata } from './globalProps.types'

/**
 * This file is designed to read global props from AEM/HTML
 * and make available at application level with Redux
 */
export class GlobalHelper {
    // eslint-disable-next-line no-warning-comments
    // TODO: improvement required [vinay.mavi] need to work
    /**
     * This function read data from config
     * @return {Record<string, unknown>} configs
     */
    readDataConfig(): GlobalHelperConfig {
        return this.dataset<GlobalHelperConfig>('configs') as GlobalHelperConfig
    }

    /**
     * This function return property by name from dataset
     * @param {string} name of property which need to find
     * @param {boolean} isObject
     * @return {string} property
     */
    dataset<DatasetType>(name: string, isObject = true): string | DatasetType {
        const dataset = document.querySelector('body')?.dataset
        try {
            return isObject ? (JSON.parse(dataset?.[name] || '') as DatasetType) : (dataset?.[name] as string)
        } catch (error) {
            return {} as DatasetType
        }
    }
    /**
     * Generate function to read alternateLanguagesDetails data-attribute from body tag
     * @return {Record<string, unknown>} returns alternate language details JSON
     */
    readDataAlternateLangDetails(): ISEOmetadata {
        return this.dataset<ISEOmetadata>('seometadata') as ISEOmetadata
    }

    /**
     * This function read data config
     * @return {Record<string, unknown>} dataConfig
     */
    init() {
        return this.readDataConfig()
    }

    /**
     * This function delete query parameters from alternative path url
     * @param {string} requestKey
     */
    cutParameterFromAlternativePath(requestKey: string): void {
        const languageData = this.dataset<ISEOmetadata>('seometadata') as ISEOmetadata
        const updatedAlternativePath = new URL(languageData.alternatePageDetails.path)
        updatedAlternativePath.searchParams.delete(requestKey)
        languageData.alternatePageDetails.path = updatedAlternativePath.toString()
        document.querySelector('body')!.dataset.seometadata = JSON.stringify(languageData)
    }

    /**
     * This function delete query parameters from current path url
     * @param {string} requestKey
     */
    cutParameterFromCurrentPath(requestKey: string): void {
        const languageData = this.dataset<ISEOmetadata>('seometadata') as ISEOmetadata
        const updatedCurrentPath = new URL(languageData.currentPageDetails.path)
        updatedCurrentPath.searchParams.delete(requestKey)
        languageData.currentPageDetails.path = updatedCurrentPath.toString()
        document.querySelector('body')!.dataset.seometadata = JSON.stringify(languageData)
        window.history.pushState(null, document.title, updatedCurrentPath.pathname.toString())
    }

    /**
     * Function to get allowedDomainsWithHeaders configuration
     * @return {Record<string, unknown>}
     */
    readAllowedDomainsWithHeadersConfig(): Record<string, unknown> {
        return this.dataset<Record<string, unknown>>('alloweddomainswithheaders') as Record<string, unknown>
    }

    /**
     * Function to return document referrer
     * @return {string}
     */
    getDocumentReferrer(): string {
        return document.referrer
    }
}

export default GlobalHelper
