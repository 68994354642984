import React, { useState } from 'react'
import qs, { ParsedUrlQueryInput } from 'querystring'
import PropTypes from 'prop-types'

import { contactUsService } from '../../services/contactUsService/contactus.service'

import { ContactUsByEmail } from '@nl/lib'
import { ContactUsByEmailType, ContactUsFormDetailsType } from './ContactUsForm.type'
import { emailRegEx, firstNameRegex, lastNameRegex } from '../../globalConstants/regexPatterns.constant'

/**
 * ContactUsForm Component
 *  @param {ContactUsByEmailType} props
 * @return {JSX.Element} returns ContactUsForm
 */
const ContactUsForm: React.FC<ContactUsByEmailType> = (props: ContactUsByEmailType) => {
    const {
        additionalInfoMaxCount,
        firstNameInvalidMessage,
        firstNameEmptyMessage,
        lastNameEmptyMessage,
        lastNameInvalidMessage,
        emailEmptyMessage,
        emailInvalidMessage,
        subjectEmptyMessage,
    } = props
    const [contactUsSuccess, setContactUsSuccess] = useState(false)
    const [contactUsFailure, setContactUsFailure] = useState(false)

    const postContactUs = (requestPayload: ContactUsFormDetailsType) => {
        contactUsService
            .postContactUs(qs.stringify(requestPayload as unknown as ParsedUrlQueryInput))
            .then(() => {
                setContactUsSuccess(true)
            })
            .catch(() => {
                setContactUsFailure(true)
            })
    }

    const resetContactUsAction = () => {
        setContactUsSuccess(false)
        setContactUsFailure(false)
    }

    const [showSpinner, setShowSpinner] = useState(false)

    /**
     * Contact us Form submit function
     * @param {ContactUsFormDetailsType} formDetails - contact us form details
     */
    const submitContactUsCallBack = (formDetails: ContactUsFormDetailsType): void => {
        postContactUs(formDetails)
        showSpinner && setShowSpinner(false)
    }

    /**
     * Contact us Form reset function
     */
    const resetContactUsCallBack = (): void => {
        resetContactUsAction() // Reset the contact success and failure store props.
    }

    return (
        <ContactUsByEmail
            {...props}
            accordionItems={[]}
            additionalInfoMaxCount={Number(additionalInfoMaxCount)}
            submitContactUsCallBack={submitContactUsCallBack}
            resetContactUsCallBack={resetContactUsCallBack}
            contactUsSuccess={contactUsSuccess}
            contactUsFailure={contactUsFailure}
            firstNameRegEx={firstNameRegex}
            lastNameRegEx={lastNameRegex}
            emailRegEx={emailRegEx}
            firstNameInvalidMessage={firstNameInvalidMessage}
            firstNameEmptyMessage={firstNameEmptyMessage}
            lastNameEmptyMessage={lastNameEmptyMessage}
            lastNameInvalidMessage={lastNameInvalidMessage}
            emailEmptyMessage={emailEmptyMessage}
            emailInvalidMessage={emailInvalidMessage}
            orderNumberEmptyMessage={''}
            invalidOrderNumberMessage={''}
            optionEmptyMessage1={subjectEmptyMessage}
            optionEmptyMessage2={''}
            optionEmptyMessage3={''}
            isAssistiveTextAlwaysVisible={true}
        />
    )
}

ContactUsForm.propTypes = {
    title: PropTypes.string.isRequired,
    firstNameLabel: PropTypes.string.isRequired,
    lastNameLabel: PropTypes.string.isRequired,
    emailLabel: PropTypes.string.isRequired,
    yourMessageTitle: PropTypes.string.isRequired,
    subjectLabel: PropTypes.string.isRequired,
    orderNumberLabel: PropTypes.string.isRequired,
    additionalInfoLabel: PropTypes.string.isRequired,
    additionalInfoMaxCount: PropTypes.string.isRequired,
    noteText: PropTypes.string.isRequired,
    submitCTALabel: PropTypes.string.isRequired,
    ssCTATarget: PropTypes.string.isRequired,
    esDescription: PropTypes.string.isRequired,
    ssTitle: PropTypes.string.isRequired,
    esCTAPath: PropTypes.string.isRequired,
    ssDescription: PropTypes.string.isRequired,
    additionalInfoEmptyMsg: PropTypes.string.isRequired,
    esTitle: PropTypes.string.isRequired,
    ssCTAPath: PropTypes.string.isRequired,
    esImagePath: PropTypes.string.isRequired,
    esImageAlt: PropTypes.string.isRequired,
    ssImagePath: PropTypes.string.isRequired,
    esCTALabel: PropTypes.string.isRequired,
    ssImageAlt: PropTypes.string.isRequired,
    destinationEmail: PropTypes.string.isRequired,
    ssCTALabel: PropTypes.string.isRequired,
    esCTATarget: PropTypes.string.isRequired,
    accordionItems: PropTypes.array.isRequired,
    emailMessageTitle: PropTypes.string.isRequired,
    headingLevel: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
}

export default ContactUsForm
