/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios, { AxiosPromise, AxiosRequestHeaders } from 'axios'
import GlobalHelper from '../helpers/global.helper'
import { getEnvironment } from '../environments'
import { RequestBodyType } from './client.type'
import { setRequestHeadersToAllowedDomains } from '@nl/lib/src/utils/HttpClientUtils/axiosHttpClientUtils'

// eslint-disable-next-line no-warning-comments
// TODO: environment access should be avoid in
// eslint-disable-next-line no-warning-comments
// TODO: Http Client need improvement
const environment = getEnvironment()
const globalProps = new GlobalHelper()
const configs = globalProps.init()
const domainsConfig = globalProps.readAllowedDomainsWithHeadersConfig()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CustomAxiosStatic = { defaults: { headers: { post: { 'Content-Type': string } } } }
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const axiosInstance = axios.create({})

axiosInstance.interceptors.request.use(config => {
    const headers = config.headers as Record<string, unknown>
    if (!config.headers) config.headers = {}
    setRequestHeadersToAllowedDomains(environment, configs, domainsConfig, config.url || '', '', headers)
    return config
})

export const apiPost = <T>(
    url: URL | string,
    requestBody?: RequestBodyType | string,
    headers?: AxiosRequestHeaders,
    withCredentials = false,
): AxiosPromise => {
    return axiosInstance.post<T>(url.toString(), requestBody, {
        headers,
        withCredentials: withCredentials,
    })
}

export default {
    apiPost,
}
