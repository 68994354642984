import React, { useEffect } from 'react'
import { useInspirationData } from '../../hooks/inspirationData.hook'
import { InspirationComponentProps } from './Inspiration.types'
import { renderAEMComponents } from '../../helpers/cms.helper'

const InspirationComponent: React.FC<InspirationComponentProps> = ({ targetSelector, fragmentVariationPath }) => {
    const [inspirationData, isFetching] = useInspirationData(fragmentVariationPath)

    useEffect(() => {
        if (inspirationData && !isFetching) {
            const element = document.querySelector(targetSelector) as HTMLElement
            if (element) {
                element.innerHTML = inspirationData.content
                renderAEMComponents(element)
            }
        }
    }, [inspirationData, isFetching, targetSelector])

    return null
}

export default InspirationComponent
