import React from 'react'
import PropTypes from 'prop-types'

import { LanguageToggle } from '@nl/lib'
import { LanguageToggleHandlerProps } from './LanguageToggleHandler.type'
import { GlobalHelper } from '../../helpers/global.helper'

/**
 * Generate Language Toggle Handler Component To Be Used In Pencil Banner
 * @return {JSX.Element} returns Language Toggle Handler  component
 *
 **/
const LanguageToggleHandler: React.FC<LanguageToggleHandlerProps> = ({ ...props }): JSX.Element => {
    const { languageLabel = '', alternateLanguageA11y = '' } = props
    const globalProps = new GlobalHelper()
    const { alternatePageDetails } = globalProps.readDataAlternateLangDetails()
    /**
     * Generate function to return language updated on toggle in lang key when page is static
     * @return {string} returns page path
     */
    const getLangUrlParams = (): string => {
        return window.location.search
    }
    /**
     * Generate function to return page path on the basis of property isDynamic(true/false),whether page is dynamic or not
     * @return {string} returns page path
     */
    const getAlternateLangUrl = (): string => {
        return `${alternatePageDetails?.path ? alternatePageDetails?.path : ''}${getLangUrlParams()}`
    }

    return (
        <LanguageToggle
            languageLabel={languageLabel}
            alternateLanguageA11y={alternateLanguageA11y}
            getAlternateUrl={getAlternateLangUrl}
        />
    )
}

LanguageToggleHandler.propTypes = {
    languageLabel: PropTypes.string.isRequired,
    alternateLanguageA11y: PropTypes.string.isRequired,
}

export default LanguageToggleHandler
