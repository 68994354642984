import { Dispatch } from 'redux'

import { setShowSpinner } from '../actionCreators/spinner.actionCreators'
import { SET_SHOW_SPINNER } from '../types/spinner/spinner.actionTypes.constant'

export const showSpinner =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_SHOW_SPINNER; payload: boolean }>): void => {
        dispatch(setShowSpinner(requestPayload))
    }
