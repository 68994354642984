import axiosClient from './axiosHttpClient'

/**
 * Http Client provider
 * @return {HttpClient}
 */
const getHttpClient = () => {
    // eslint-disable-next-line no-warning-comments
    // TODO: httpClient return type generic implementation need to be created
    return axiosClient
}

export { getHttpClient }
export default getHttpClient
