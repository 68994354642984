export const firstNameRegex = /^[a-zA-ZÀ-ÿ\-'’\s]+$/
export const lastNameRegex = /^[a-zA-ZÀ-ÿ\-'’\s]+$/
export const addressRegex = /^[a-zA-ZÀ-ÿ0-9,.'’/\\\-\s]+$/
export const cityRegex = /^[a-zA-ZÀ-ÿ,.'’/\\\-\s]+$/
export const postalCodeRegex = /^ *(?=[^dfioquwz])[a-z]\d(?=[^dfioqu])[a-z] ?\d(?=[^dfioqu])[a-z]\d *$/i
export const postalCodeRegexUSA = /^ *[0-9]{5}(?:-[0-9]{4})? *$/
export const telephoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
export const phoneNumberFormat = /^(\d{3})(\d{3})(\d{4})$/
export const emailRegEx =
    // eslint-disable-next-line max-len
    /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // Email regex value
export const numberRegEx = /^(([0-9-])*)$/
export const allWhitespace = /\s/g
export const vehicleHeaderRegex = /^\s+|\s+$/g
export const alphanumericRegex = /^[a-z0-9]+$/i
export const cirCardFormatRegex = /^(.{4})(.{5})(.{4})(.*)$/
