import { EnvironmentConfig } from './base.environment'
import { DevConfig } from './dev.environment'
import { LocalConfig } from './local.environment'
import { EnvironmentType } from './environment.types'

/**
 * Provider function to provide environment config class.
 * @param  {EnvironmentType} envName?
 * @return {EnvironmentConfig}
 */
export function getEnvironment(envName?: EnvironmentType): EnvironmentConfig {
    const environmentName =
        envName || window.location.origin?.includes('localhost') ? EnvironmentType.local : EnvironmentType.dev

    let devConfig
    let localConfig
    if (environmentName === EnvironmentType.local) {
        // read config from HTML body
        localConfig = new LocalConfig()
        // read config from HTML body
        localConfig.setConfig()
        return localConfig
    } else {
        devConfig = new DevConfig()
        // read config from HTML body
        devConfig.setConfig()
        return devConfig
    }
}

export { EnvironmentType, EnvironmentConfig }
