// Custom Components

import PageInit from '../components/PageInit'
import InspirationTabs from '../components/InspirationComponent/InspirationTabs'
import LanguageToggleHandler from '../components/LanguageToggle/LanguageToggleHandler'
import ContactUsForm from '../components/ContactUsForm'

export const staticComponentMap: {
    [key: string]: unknown
} = {
    PageInit,
    InspirationTabs,
    LanguageToggleHandler,
    ContactUsFormComponent: ContactUsForm,
}
