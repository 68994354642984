// import { pageTypes } from './../../config'
// import { StaticSEO as staticSEO } from '../../Seo/components/Hoc/StaticSEO'
// import { staticPrerender } from '../../components/prerender/StaticPrerender'
// import { getPageType } from '@nl/lib'
// import { initAnalytics } from '../../analytics'
// import { lazyLoadImage } from '../../helpers/lazyLoadImage.helper'

/**
  function to call on each page load
 */
const pageLoad = (): void => {
    // call seo methods on page load
    // staticSEO()
    //
    // call lazyLoadImage method on page load
    // lazyLoadImage()
    //
    // call initAnalytics methods on page load for promo
    // initAnalytics()
    //
    // register for anchor link click analytics
    // initAnchorClickAnalytics()
    //
    // add main div in all pages after 1st dynamic fragment for "skip link" accessibility
    // const isAccountPage = getPageType() === pageTypes.account
    // if (isAccountPage) {
    //     document.querySelector('.nl-account-page-section')?.setAttribute('id', 'main')
    //     document.querySelector('.accountdashboard')?.setAttribute('id', 'side-navigation')
    // }
    // else {
    //     document.querySelector('.dynamicexperiencefragment')?.insertAdjacentHTML('afterend', '<main id="main"></main>')
    // }
    // enable the prerender flag for static pages
    // staticPrerender()
}
export default pageLoad
