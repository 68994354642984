import React from 'react'
import { TabData } from './Inspiration.types'
import InspirationComponent from './InspirationComponent'

const InspirationBanner: React.FC<{ tab: TabData | undefined }> = ({ tab }) => {
    return (
        <InspirationComponent
            targetSelector=".inspiration-banner"
            fragmentVariationPath={tab?.fragmentVariationPathTop || ''}
        />
    )
}

export default InspirationBanner
