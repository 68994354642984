import React, { useEffect, useState, useRef, useCallback } from 'react'
import InspirationBanner from './InspirationBanner'
import InspirationContent from './InspirationContent'
import TabButton from './InspirationTabButton'
import { TabData, InspirationTabsProps } from './Inspiration.types'
import { PREFIX } from '@nl/lib/src/components/config'

const TAB_PREFIX = `${PREFIX}-inspiration-tabs`
const TAB_CONTAINER_PREFIX = `${PREFIX}-inspiration-tabs__container`
const SCROLL_ADJUSTMENT = 0.75

const InspirationTabs: React.FC<InspirationTabsProps> = ({ tabs, scrollLeftLabel, scrollRightLabel }) => {
    const [activeTabName, setActiveTabName] = useState<string | null>(null)
    const [activeTab, setActiveTab] = useState<TabData | null>(null)
    const [shouldRender, setShouldRender] = useState(false)
    const [showPrevButton, setShowPrevButton] = useState(false)
    const [showNextButton, setShowNextButton] = useState(true)
    const tabListRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const tabParamUrl = urlParams.get('tab')

        if (tabParamUrl) {
            setActiveTabName(tabParamUrl)
        } else {
            setShouldRender(true)
            const hash = window.location.hash.toLowerCase().replace('#', '')

            if (hash) {
                setActiveTabName(hash)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (tabs.length > 0) {
                setActiveTabName(tabs[0].tabHash)
            }
        }
    }, [tabs])

    useEffect(() => {
        if (activeTabName) {
            const foundTab = tabs.find(tab => tab.tabHash === activeTabName)
            setActiveTab(foundTab || null)
        }
    }, [activeTabName, tabs])

    useEffect(() => {
        const container = containerRef.current
        const tabList = tabListRef.current

        const updateScrollButtons = () => {
            if (container && tabList) {
                const containerWidth = container.offsetWidth
                const tabListWidth = tabList.scrollWidth
                const scrollLeft = container.scrollLeft
                setShowPrevButton(scrollLeft > 0)
                setShowNextButton(scrollLeft + containerWidth < tabListWidth)
            }
        }

        const handleScroll = () => {
            updateScrollButtons()
        }

        updateScrollButtons()
        container?.addEventListener('scroll', handleScroll)

        return () => {
            container?.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleTabClick = useCallback((clickedTab: TabData) => {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('tab', clickedTab.tabHash)
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`
        setActiveTabName(clickedTab.tabHash)
        window.history.pushState(null, '', newUrl)
        setShouldRender(true)
    }, [])

    const handleArrowButtonClick = useCallback((direction: 'prev' | 'next') => {
        const container = containerRef.current

        if (container) {
            const containerWidth = container.offsetWidth
            const scrollAmount = direction === 'prev' ? -containerWidth : containerWidth

            container.scrollBy({
                left: scrollAmount * SCROLL_ADJUSTMENT,
                behavior: 'smooth',
            })
        }
    }, [])

    return (
        <>
            {shouldRender && activeTab && <InspirationBanner tab={activeTab} />}
            <div className={TAB_CONTAINER_PREFIX} ref={containerRef}>
                <div className={`${TAB_CONTAINER_PREFIX}-wrapper`} ref={tabListRef}>
                    <div className={`${TAB_CONTAINER_PREFIX}-list`}>
                        {tabs.map(tab => (
                            <TabButton
                                key={tab.tabHash}
                                tab={tab}
                                isActive={activeTabName === tab.tabHash}
                                onClick={() => handleTabClick(tab)}
                            />
                        ))}
                    </div>
                </div>
                <button
                    className={`${TAB_PREFIX}__scroll ${TAB_PREFIX}__scroll_prev ${
                        showPrevButton ? '' : `${TAB_PREFIX}__scroll_hidden`
                    }`}
                    aria-label={scrollLeftLabel}
                    onClick={() => handleArrowButtonClick('prev')}
                />
                <button
                    className={`${PREFIX}-inspiration-tabs__scroll ${PREFIX}-inspiration-tabs__scroll_next ${
                        showNextButton ? '' : `${TAB_PREFIX}__scroll_hidden`
                    }`}
                    aria-label={scrollRightLabel}
                    onClick={() => handleArrowButtonClick('next')}
                />
            </div>
            {shouldRender && activeTab && <InspirationContent tab={activeTab} />}
        </>
    )
}

export default InspirationTabs
