import React from 'react'
import { TabData } from './Inspiration.types'
import InspirationComponent from './InspirationComponent'

const InspirationContent: React.FC<{ tab: TabData | undefined }> = ({ tab }) => {
    return (
        <InspirationComponent
            targetSelector=".inspiration-content"
            fragmentVariationPath={tab?.fragmentVariationPathBottom || ''}
        />
    )
}

export default InspirationContent
