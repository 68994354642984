import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../redux/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
// eslint-disable-next-line sonar/no-wildcard-import
import * as actionCreators from '../redux/actions'

// Below modification is used to get the trace option in the redux dev tool.
const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
})

const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default configureStore()
