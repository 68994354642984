const minimumCards = 4 // Declared to resolve typescript error - no magic numbers.
const $ = window.$ // Defined in the external.ts file.

/**
 * carouselJS - converts the div element which is accessed below in to carousel using slick js.
 * @param {Element} element - HTML element
 * @param {Record<string, string>} params - Additional Params which includes slickClass.
 */
const SlickCarouselComponent = (element?: Element, params?: Record<string, string>): void => {
    const slickElement = $(element).children(`.${String(params?.slickClass)}`)
    if (slickElement.children().length > minimumCards) {
        // Apply slick on the each instance of regular class.
        slickElement.slick({
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            prevArrow:
                // eslint-disable-next-line max-len
                '<button type="button" data-qm-allow="true" aria-label="previous" class="slick-prev"><svg viewBox="0 0 20 20" fill="#262626" width="20" height="20"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/></svg></path></svg></button>',
            nextArrow:
                // eslint-disable-next-line max-len
                '<button type="button" data-qm-allow="true" aria-label="next" class="slick-next"><svg viewBox="0 0 20 20" fill="#262626" width="20" height="20"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/></svg></button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: 50,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        })
    } else {
        slickElement.slick({
            dots: false,
            infinite: false,
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: 50,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        })
    }
    // })

    /**
     * Below code is for handling padding on icon carousel.
     * nl-card--type1 refers to icon featured list.
     */
    Array.from(document.querySelectorAll('.slick-slider')).forEach(el => {
        el.querySelector('.nl-card--type1') && el.classList.add('slick-icon')
    })
}

export { SlickCarouselComponent }
