import { AxiosPromise, AxiosRequestHeaders } from 'axios'

import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Function to trigger contact us api service
 * @class
 */
class ContactUsService {
    /**
     * Function to post contact us api
     * @param {string} requestPayload
     * @return {AxiosPromise}
     */
    postContactUs(requestPayload: string): AxiosPromise<unknown> {
        const headers: AxiosRequestHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }

        const {
            API_BASE_URL,
            API_ENDPOINTS: { contactUs },
        } = environment

        return httpClient.apiPost(new URL(`${API_BASE_URL}${contactUs}`), requestPayload, headers)
    }
}

// Creating a singleton instance of service
const contactUsService = new ContactUsService()

export { contactUsService }
export default contactUsService
