type DebouncedFunc<T extends (...args: unknown[]) => unknown> = T & {
    cancel: () => void
}

export const debounce = <T extends (...args: unknown[]) => unknown>(func: T, delay: number): DebouncedFunc<T> => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined

    const debounced = (...args: Parameters<T>) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }

    debounced.cancel = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
    }

    return debounced as DebouncedFunc<T>
}
