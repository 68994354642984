/**
 * Base class to create new environment configuration
 */
export abstract class EnvironmentConfig {
    abstract API_BASE_URL: string
    abstract API_V1: string
    abstract API_MOCK_URL: string
    abstract AEM_URL: string
    abstract API_ENDPOINTS: Record<string, string>
    abstract serviceClient: string
    abstract serviceVersion: string
    abstract ocpApimSubscriptionKey: string
    abstract baseSiteId: string
    abstract baseSiteIdAddToCart: string
    abstract bannerIdAddToCart: string
    abstract SSO_BASE_URL: string
    abstract language: string
    abstract JSON_RESPONSE_FORMAT: string
    abstract banner: string
}
