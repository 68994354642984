/**
 * adds collapse for footer action bar link
 */
const footerCollapseControl = (): void => {
    const toggleHandler = (event: Event) => {
        event.preventDefault()

        const ARIA_ATTRIBUTE = 'aria-expanded'

        const toggleButton = document.querySelector('[data-testid="footer-toggle"]')
        const footerLinksContainer = document.querySelector('[data-testid="footer-links"]')

        if (toggleButton && footerLinksContainer) {
            // eslint-disable-next-line sonarjs/no-gratuitous-expressions
            let ariaValue = toggleButton && toggleButton.getAttribute(ARIA_ATTRIBUTE)

            if (ariaValue === 'true') {
                ariaValue = 'false'
                footerLinksContainer.classList.add('hide')
            } else {
                ariaValue = 'true'
                footerLinksContainer.classList.remove('hide')
            }

            toggleButton.setAttribute(ARIA_ATTRIBUTE, ariaValue)
        }
    }

    document.querySelector('[data-testid="footer-toggle"]')?.addEventListener('click', (e: Event) => toggleHandler(e))
}

export { footerCollapseControl }
