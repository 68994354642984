import { ActionType, getType } from 'typesafe-actions'

import { setShowSpinner } from '../actionCreators/spinner.actionCreators'
import * as actions from '../actionCreators/spinner.actionCreators'

type Action = ActionType<typeof actions>

export interface SpinnerState {
    showSpinner: boolean
}
const initialState: SpinnerState = {
    showSpinner: false,
}

export const spinnerReducer = (state: SpinnerState = initialState, action: Action): SpinnerState => {
    if (action.type === getType(setShowSpinner)) {
        return { ...state, showSpinner: action.payload }
    } else {
        return state
    }
}
