import React from 'react'
import { TabButtonProps } from './Inspiration.types'
import { PREFIX } from '../../config'
import classnames from 'classnames'

const TAB_ITEM_PREFIX = `${PREFIX}-inspiration-tabs__item`

const TabButton: React.FC<TabButtonProps> = ({ tab, isActive, onClick }) => {
    const className = classnames(`${TAB_ITEM_PREFIX}`, {
        [`${TAB_ITEM_PREFIX}--active`]: isActive,
    })

    const { imageType, tabIcon, tabTitle, tabHash } = tab

    return (
        <div className={`${TAB_ITEM_PREFIX}-container`} key={tabHash}>
            <button className={className} onClick={onClick}>
                <div className={`${TAB_ITEM_PREFIX}-title ${TAB_ITEM_PREFIX}-title_with_icon`}>
                    <div className={`${TAB_ITEM_PREFIX}-image-container`}>
                        <img
                            className={classnames(
                                `${TAB_ITEM_PREFIX}-image`,
                                {
                                    [`${TAB_ITEM_PREFIX}-image_active`]: isActive,
                                },
                                imageType,
                            )}
                            src={tabIcon}
                            alt={tabTitle}
                        />
                    </div>
                    <div className={`${TAB_ITEM_PREFIX}-title-message`}>{tabTitle}</div>
                </div>
            </button>
        </div>
    )
}

export default TabButton
